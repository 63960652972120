<div class="header">
    <h2>{{data?.title}}</h2>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon color="warn">close</mat-icon>
    </button>
</div>
<br>
<div class="content">
    {{data?.message}}
</div>
<br>
<div class="footer">
    <button mat-button color="warn" [mat-dialog-close]="false">{{data?.cancelText | uppercase}}</button>
    <button mat-button color="primary" [mat-dialog-close]="true">{{data?.confirmText | uppercase}}</button>
</div>