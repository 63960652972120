<button id="userMenuTrigger" mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <span (mouseleave)="userMenuTrigger.closeMenu()">
        <mat-toolbar *ngIf="user" color="primary">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                <img [src]="userImage" alt="user-image" width="60" class="rounded mat-elevation-z6">
                <p>{{user.fullName}}
                    <br>
                    <small>{{user.email}}</small>
                </p>
            </div>
        </mat-toolbar>
        <button mat-menu-item (click)="router.navigateByUrl('/account')">
            <mat-icon>person</mat-icon>
            <span>Profile</span>
    </button>
    <!-- <button mat-menu-item (click)="router.navigateByUrl('/account/settings#preferences')">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </button> -->
    <button id="userLogout" mat-menu-item (click)="logout()">
            <mat-icon>lock</mat-icon>
            <span>Logout</span>
        </button>
    </span>
</mat-menu>


<!-- <a mat-menu-item routerLink="/settings"> 
            <mat-icon>person</mat-icon>
            <span>Profile</span> 
        </a>
        <a mat-menu-item routerLink="/settings"> 
            <mat-icon>settings</mat-icon>
            <span>Settings</span> 
        </a>
    <a mat-menu-item routerLink="/"> 
            <mat-icon>lock</mat-icon>
            <span>Lock screen</span> 
        </a>
        <a mat-menu-item routerLink="help"> 
            <mat-icon>help</mat-icon>
            <span>Help</span> 
        </a>
    <div class="divider"></div>
        <a mat-menu-item routerLink="/login"> 
            <mat-icon>power_settings_new</mat-icon>
            <span>Log out</span> 
        </a> 
    </span> -->