// ===========================================
// Ground Works 2.0
// ===========================================

import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { addDays, format } from 'date-fns';


@Injectable()
export class DateService {

    getCurrentWeekDates(): Date[] {
        const weekDates = [];
        const todaysDate = new Date();
        let sunday = new Date();
        const day = todaysDate.getDay();

        sunday = addDays(todaysDate, -day);
        weekDates.push(sunday);
        weekDates.push(addDays(sunday, 1));
        weekDates.push(addDays(sunday, 2));
        weekDates.push(addDays(sunday, 3));
        weekDates.push(addDays(sunday, 4));
        weekDates.push(addDays(sunday, 5));
        weekDates.push(addDays(sunday, 6));

        return weekDates;
    }

    getCurrentWeekDatesAsString(): string[] {
        const weekDates = [];
        const todaysDate = new Date();
        let sunday = new Date();
        const day = todaysDate.getDay();

        sunday = addDays(todaysDate, -day);
        weekDates.push(format(sunday, 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 1), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 2), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 3), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 4), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 5), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 6), 'MM/dd/yyyy'));

        return weekDates;
    }

    getPreviousWeekDates(currentWeek: string[]): string[] {
        const week = [];
        if (currentWeek.length === 7) {
            for (let i = 0; i < currentWeek.length; i++) {
                week.push(format(addDays(new Date(currentWeek[i]), -7), 'MM/dd/yyyy'));
            }
            return week;
        }
        else {
            console.log('Invalid week dates - Need array with 7 dates');
        }
    }

    getNextWeekDates(currentWeek: string[]): string[] {
        const week = [];
        if (currentWeek.length === 7) {
            for (let i = 0; i < currentWeek.length; i++) {
                week.push(format(addDays(new Date(currentWeek[i]), 7), 'MM/dd/yyyy'));
            }
            return week;
        }
        else {
            console.log('Invalid week dates - Need array with 7 dates');
        }
    }

    getWeekDates(startDate: Date): string[] {
        const weekDates = [];
        let sunday = new Date();
        const day = startDate.getDay();
        sunday = addDays(startDate, -day);
        weekDates.push(format(sunday, 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 1), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 2), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 3), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 4), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 5), 'MM/dd/yyyy'));
        weekDates.push(format(addDays(sunday, 6), 'MM/dd/yyyy'));
        return weekDates;
    }

    getWeekDayIndex(name: string): number {
        switch (name.toLowerCase()) {
            case 'sunday':
                return 0;
                break;
            case 'monday':
                return 1;
                break;
            case 'tuesday':
                return 2;
                break;
            case 'wednesday':
                return 3;
                break;
            case 'thursday':
                return 4;
                break;
            case 'friday':
                return 5;
                break;
            case 'saturday':
                return 6;
                break;
            default:
                break;
        }
    }
}
