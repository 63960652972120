import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LayoutComponent } from './shared/layout/layout.component';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
// import { SettingsComponent } from './auth/account-mgmt/settings/settings.component';
import { AuthGuard } from './security/shared/services/auth-guard.service';



export const routes: Routes = [

    {
        path: '', component: LayoutComponent, children: [
            { path: '', redirectTo: '/landing', pathMatch: 'full' },
            {
                path: 'approvals', loadChildren: () => import('./dailys/approval/dailys-approval.module').then(m => m.DailysApprovalModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'dailys', loadChildren: () => import('./dailys/crew-dailys/crew-dailys.module').then(m => m.CrewDailysModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'projects/:id/portal', loadChildren: () => import('./projects/portal/project-portal.module').then(m => m.ProjectPortalModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'projects/:id/dailys', loadChildren: () => import('./dailys/crew-dailys/crew-dailys.module').then(m => m.CrewDailysModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'employees', loadChildren: () => import('./security/users/users.module').then(m => m.UsersModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'Employees' }
            },
            // {
            //     path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
            //     canActivate: [AuthGuard],
            //     data: { breadcrumb: 'Ar Aging' }
            // },
            {
                path: 'account', loadChildren: () => import('./security/profile/user-profile.module').then(m => m.UserProfileModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'users', loadChildren: () => import('./security/users/users.module').then(m => m.UsersModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'roles', loadChildren: () => import('./security/roles/roles.module').then(m => m.RolesModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'crews', loadChildren: () => import('./crews/crews.module').then(m => m.CrewsModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'quantities', loadChildren: () => import('./dailys/quantities/quantities.module').then(m => m.QuantitiesModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'timecards', loadChildren: () => import('./dailys/timecards/timecards.module').then(m => m.TimecardsModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'mytimecard', loadChildren: () => import('./dailys/timecards/user-timecard/user-timecard.module').then(m => m.UserTimecardModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'reports/cost-summary', loadChildren: () => import('./reports/cost-summary-reports/cost-summary-reports.module').then(m => m.CostSummaryReportsModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'receipts', loadChildren: () => import('./accounting/receipts/receipts.module').then(m => m.ReceiptsModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'creditcards', loadChildren: () => import('./accounting/credit-cards/credit-cards.module').then(m => m.CreditCardsModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            },
            {
                path: 'creditcardtransactions', loadChildren: () => import('./accounting/credit-card-transactions/credit-card-transactions.module').then(m => m.CreditCardTransactionsModule),
                canActivate: [AuthGuard], data: { breadcrumb: 'home' }
            }
        ]
    },
    { path: 'login', loadChildren: () => import('./security/auth/login/login.module').then(m => m.LoginModule), data: { title: 'Login' } },
    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule), data: { title: 'Landing' } },
    { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule), data: { title: 'Privacy' } },
    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
});
