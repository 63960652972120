
<!-- Logo and Componay Name -->
<mat-toolbar color="primary" [fxLayoutAlign]="(settings.menuType != 'mini') ? 'space-between center' : 'center center'" class="sidenav-header">
    <a mat-raised-button color="accent" routerLink="/" (click)="closeSubMenus()" class="small-logo">G</a>
    <a *ngIf="settings.menuType == 'default'" class="logo" routerLink="/" (click)="closeSubMenus()">Ground Works</a> 
    <svg *ngIf="settings.menuType != 'mini'" class="pin" (click)="settings.sidenavIsPinned = !settings.sidenavIsPinned">
        <path *ngIf="!settings.sidenavIsPinned" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
        <path *ngIf="settings.sidenavIsPinned" d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
    </svg>  
</mat-toolbar>
<!-- End of Logo and Componay Name -->

<!-- ==================================================================================================================================== -->

<!-- Company Infor -->
<app-company-info></app-company-info>

<mat-divider></mat-divider>
<!-- End of User Infor -->

<!-- ==================================================================================================================================== -->

<!-- Side Nav Links -->
<div id="sidenav-menu-outer" class="sidenav-menu-outer" perfectScrollbar [class.user-block-show]="settings.sidenavUserBlock">    
    <span *ngIf="!menuItems">loading....</span>
    <app-vertical-menu [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu> 
</div>
<!-- End of Side Nav Links -->