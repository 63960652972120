<div class="header">
    <h2>Edit Timecard</h2>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon color="warn">close</mat-icon>
    </button>
</div>
<br>
<div class="content">
    <form [formGroup]="formGroup">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field  appearance="fill" fxFlex="49">
                <mat-label>Employee</mat-label>
                <input matInput type="text" formControlName="employeeCtrl">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="dateCtrl">
                <!-- <mat-error *ngIf="dateCtrl.invalid">Please enter a valid date</mat-error> -->
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Project</mat-label>
            <input matInput type="text" formControlName="projectCtrl">
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex.xs="90" class="full-width">
            <mat-label>CostCode</mat-label>
            <input matInput aria-label="CostCode" [matAutocomplete]="autoCode" formControlName="costCodesCtrl">
            <mat-icon matSuffix>playlist_add</mat-icon>
            <mat-autocomplete #autoCode="matAutocomplete" [displayWith]="getCostCodeOptionText" [panelWidth]="350"
                (optionSelected)='onCostCodeChange()'>
                <mat-option *ngFor="let costCode of filteredCostCodes | async" [value]="costCode">
                    <span>{{costCode.CostCodeNo}} - </span>
                    <small>{{costCode.Description}}</small>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Hours</mat-label>
            <input matInput type="number" step="0.01" value="" formControlName="hoursCtrl"
                (click)="$event.target.select()" (change)="onHoursChange()" style="text-align: center;">
            <span matSuffix>Hrs</span>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Notes</mat-label>
            <input matInput type="text" formControlName="notesCtrl" (click)="$event.target.select()"
                (change)="onNotesChange()">
        </mat-form-field>

        <br>
        <div class="footer">
            <button mat-button color="primary" [mat-dialog-close]="null">CANCEL</button>
            <button mat-button color="warn" [mat-dialog-close]="result" [disabled]="!formGroup.valid">SAVE</button>
        </div>
    </form>
</div>