import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './shared/utils/custom-overlay-container';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { AppSettings } from './settings/app.settings';
import { AlertService } from './shared/services/alert.service';
import { AppTranslationService, TranslateLanguageLoader } from './shared/services/app-translation.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { LocalStoreManager } from './shared/services/local-store-manager.service';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { LayoutComponent } from './shared/layout/layout.component';
import { SidenavComponent } from './shared/layout/sidenav/sidenav.component';
import { UserMenuComponent } from './shared/layout/header/user-menu/user-menu.component';
import { FullScreenComponent } from './shared/layout/header/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from './shared/layout/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './shared/layout/menu/vertical-menu/vertical-menu.component';
import { BreadcrumbComponent } from './shared/layout/breadcrumb/breadcrumb.component';
import { CompanyInfoComponent } from './shared/layout/sidenav/company-info/company-info.component';
import { AuthInterceptor } from './security/shared/services/auth.interceptor';
import { AuthService } from './security/shared/services/auth.service';
import { AuthGuard } from './security/shared/services/auth-guard.service';
import { EndpointFactory } from './security/shared/services/endpoint-factory.service';
import { AccountService } from './security/shared/services/account.service';
import { AccountEndpoint } from './security/shared/services/account-endpoint.service';
import { DeleteTimecardDialogComponent } from './dailys/timecards/delete-timecard-dialog/delete-timecard-dialog.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { CompanyService } from './company/company.service';
import { UserService } from './security/users/services/user.service';
import { DateService } from './shared/services/date.service';
import { SettingService } from './company/settings/setting.service';


@NgModule({

  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    PerfectScrollbarModule,
    FlexLayoutModule,
    SharedModule,
    routing,
    LayoutModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      preventDuplicates: true
    }),
  ],

  declarations: [
    AppComponent,
    LayoutComponent,
    SidenavComponent,
    UserMenuComponent,
    FullScreenComponent,
    HorizontalMenuComponent,
    VerticalMenuComponent,
    BreadcrumbComponent,
    CompanyInfoComponent,
    DeleteTimecardDialogComponent,
    ConfirmDialogComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AppSettings,
    AlertService,
    AppTranslationService,
    AuthService,
    AuthGuard,
    ConfigurationService,
    LocalStoreManager,
    EndpointFactory,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    CompanyService,
    UserService,
    AccountService,
    AccountEndpoint,
    DateService,
    SettingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
