<div class="header">
    <h2>Select File</h2>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon color="warn">close</mat-icon>
    </button>
</div>
<br>
<div class="content">
    <input #fileCtrl type="file" id="fileSelector" accept=".csv" (change)="onSelectedFileChange($event)"
        class="file-input" />
    <div fxLayout="row" class="file-upload" style="width: 100%;">
        <div fxFlex="90" fxLayoutAlign="start center">
            {{fileCtrl?.files[0]?.name || "No file uploaded yet."}}
        </div>

        <div fxLayoutAlign="end end">
            <button mat-mini-fab color="" class="upload-btn" (click)="fileCtrl.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class="footer">
    <button mat-button color="warn" [mat-dialog-close]="null">CANCEL</button>
    <button mat-button color="primary" [mat-dialog-close]="fileCtrl.files"
        [disabled]="fileCtrl.files.length === 0">UPLOAD</button>
</div>