import { HttpInterceptor, HttpResponse, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LocalStoreManager } from '../../../shared/services/local-store-manager.service';
import { DBkeys } from './../models/db-keys';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private localStorage: LocalStoreManager, private _router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const access_token = this.localStorage.getData(DBkeys.ACCESS_TOKEN);
        if (access_token) {
            // console.log('Interceptor - Token present');
        }
        else {
            console.log('Interceptor - No token');
            // this._router.navigateByUrl('/login');
        }

        const clone = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + access_token)
        });


        return next.handle(clone).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // redirect to the login route
                    this._router.navigateByUrl('/login');
                }
            }
        }));
    }
}


