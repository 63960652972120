import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from '../../../security/users/services/user.service';


@Component({
  selector: 'app-edit-credit-card-dialog',
  templateUrl: './edit-credit-card-dialog.component.html',
  styleUrls: ['./edit-credit-card-dialog.component.scss']
})
export class EditCreditCardDialogComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) cardHolderAuto: MatAutocompleteTrigger;
  filteredUsers: Observable<any[]>;
  formGroup: FormGroup;
  result = {}

  constructor(public dialogRef: MatDialogRef<EditCreditCardDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private _userService: UserService) {
  }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('app-confirm-dialog');
    this.buildForm();
    this.loadUsersAutoComplete(this.data.users)
  }

  ngAfterViewInit(): void {
    let options = this.cardHolderAuto.autocomplete.options.toArray();
    const index = options.findIndex(o => o.value.Id === this.data.creditCard.CardHolderId);
    this.formGroup.controls['CardHolder'].setValue(options[index].value)
    if (this.data?.creditCard.CardHolderId !== null || (this.data?.creditCard.Imported && this.data?.creditCard.CardHolderId !== null)) {
      this.formGroup.controls['CardHolder'].disable();
    }
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      'CardHolder': new FormControl('', [Validators.required]),
      'CardHolderFirstName': new FormControl({ value: this.data?.creditCard.CardHolderFirstName, disabled: true }, [Validators.required]),
      'CardHolderLastName': new FormControl({ value: this.data?.creditCard.CardHolderLastName, disabled: true}, [Validators.required]),
      'AccountNumber': new FormControl({ value: this.data?.creditCard.AccountNumber, disabled: true }, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      'CreditLine': new FormControl(this.data?.creditCard.CreditLine),
      'IssuedOn': new FormControl(this.data?.creditCard.IssuedOn),
      'ExpiresOn': new FormControl(this.data?.creditCard.ExpiresOn)
    });
  }

  getUserOptionText(user: any) {
    return user && user.Name ? `${user.Name}` : '';
  }

  private loadUsersAutoComplete(users: any[]) {
    this.filteredUsers = this.formGroup.controls['CardHolder'].valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value?.Name)),
        map(name => (name ? this._filterUsers(name) : users.slice())),
      );
  }

  private _filterUsers(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.data.users.filter(u => u.Name.toLowerCase().includes(filterValue));
  }

  onUserChange(): void {
    const user = this.formGroup.controls['CardHolder'].value
    this.formGroup.controls['CardHolderFirstName'].setValue(user.FirstName);
    this.formGroup.controls['CardHolderLastName'].setValue(user.LastName);
  }

}
