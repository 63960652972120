import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
  fileName
  constructor(public dialogRef: MatDialogRef<FileUploadDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('app-confirm-dialog');
  }

  onSelectedFileChange(event: any): void {
    const file: File = event.target.files[0];

    if (file) {

      this.fileName = file.name;
    }
  }

}
