import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-credit-card-dialog',
  templateUrl: './create-credit-card-dialog.component.html',
  styleUrls: ['./create-credit-card-dialog.component.scss']
})
export class CreateCreditCardDialogComponent implements OnInit {
  formGroup: FormGroup;
  result = {}

  constructor(public dialogRef: MatDialogRef<CreateCreditCardDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { 

  }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('app-confirm-dialog');
    this.buildForm();
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      'cardHolderFirstName': new FormControl(this.data?.FirstName,  [Validators.required]),
      'cardHolderLastName': new FormControl(this.data?.LastName,  [Validators.required]),
      'accountNumber': new FormControl('',  [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      'creditLine': new FormControl(),
      'issuedOn': new FormControl(),
      'expiresOn': new FormControl()
    });
  }
}
