import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-edit-timecard-dialog',
  templateUrl: './edit-timecard-dialog.component.html',
  styleUrls: ['./edit-timecard-dialog.component.scss']
})
export class EditTimecardDialogComponent implements OnInit {

  formGroup: FormGroup;
  filteredCostCodes: Observable<any[]>;
  selectedCostCode;
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

  result = {}

  constructor(public dialogRef: MatDialogRef<EditTimecardDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('app-confirm-dialog');
    this.buildForm();
    this.setFormValues();
  }

  ngAfterViewInit(): void {
    let options = this._auto.autocomplete.options.toArray();
    //console.log(this._auto.autocomplete.options);
    const index = options.findIndex(o => o.value.CostCodeNo === this.data.timecard.CostCode);
    this.formGroup.controls['costCodesCtrl'].setValue(options[index].value)
    const hasCrew = this.data.timecard.CrewId > 0 ? true : false;
    if (hasCrew) {
      this.formGroup.controls['costCodesCtrl'].disable();
    }

  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      'employeeCtrl': new FormControl({ value: `${this.data.timecard.Owner.Name}`, disabled: true }),
      'dateCtrl': new FormControl({ value: this.data.timecard.Date, disabled: true }),
      'projectCtrl': new FormControl({ value: `${this.data.timecard.Project.Number} - ${this.data.timecard.Project.Name}`, disabled: true }),
      'costCodesCtrl': new FormControl({ disabled: true }, [Validators.required]),
      'hoursCtrl': new FormControl({ value: this.data.timecard.Hours }, [Validators.required]),
      'notesCtrl': new FormControl({ value: this.data.timecard.Notes })
    });
  }

  private setFormValues(): void {
    this.loadCostCodesAutoComplete(this.data.costCodes)
    this.formGroup.controls['hoursCtrl'].setValue(this.data.timecard.Hours);
    this.formGroup.controls['notesCtrl'].setValue(this.data.timecard.Notes);
  }

  private loadCostCodesAutoComplete(costCodes: any[]) {
    this.filteredCostCodes = this.formGroup.controls['costCodesCtrl'].valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value?.CostCodeNo)),
        map(name => (name ? this._filterCostCodes(name) : costCodes.slice())),
      );
  }

  private _filterCostCodes(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.data.costCodes.filter(cc => cc.CostCodeNo.toLowerCase().includes(filterValue) || cc.Description.toLowerCase().includes(filterValue));
  }

  getCostCodeOptionText(costCode: any) {
    return costCode && costCode.CostCodeNo ? `${costCode.CostCodeNo} - ${costCode.Description}` : '';
  }

  onCostCodeChange(): void {
    this.result['costCode'] = this.formGroup.controls['costCodesCtrl'].value;
  }

  onHoursChange(): void {
    this.result['hours'] = this.formGroup.controls['hoursCtrl'].value;
  }

  onNotesChange(): void {
    this.result['notes'] = this.formGroup.controls['notesCtrl'].value;
  }
}
