import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project, ProjectV1 } from '../projects/project.model';
import { environment } from '../../environments/environment';
import format from 'date-fns/format';

@Injectable()
export class ProjectsService {
    apiUrl = environment.apiUrl;
    public projectEndpoint = this.apiUrl + '/Projects';

    constructor(public _httpClient: HttpClient) { }


    getProjectOdata(projectId: number, query: string) {
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})?${query}`);
    }

    getProjectsOdata(query: string) {
        return this._httpClient.get<any[]>(`${this.projectEndpoint}?${query}`);
    }

    getProjectBudgets(projectId: number, query: string): Observable<any> {
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})/Budgets?${query}`);
    }

    getBudgetChangeOrders(projectId: number): Observable<any> {
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})/GetBudgetChangeOrders()`);
    }

    getProjectCostCodes(projectId: number, query: string): Observable<any[]> {
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})/CostCodes?${query}`);
    }

    getCostCodeChangeOrders(projectId: number): Observable<any[]> {
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})/GetCostCodeChangeOrders()`);
    }


    updateProject(projectId: number, patch: any): Observable<any> {
        return this._httpClient.patch<any>(`${this.projectEndpoint}(${projectId})`, patch);
    }

    addUserToRole(roleName: string, projectId: number, userId: string): Observable<any> {
        const link = `${this.apiUrl}/Users('${userId}')`;
        const data = { '@odata.id': link };
        if (roleName.toLowerCase() === 'projectmanager') {
            return this._httpClient.put<any>(`${this.projectEndpoint}(${projectId})/ProjectManager/$ref`, data);
        }
        if (roleName.toLowerCase() === 'projectmanager2') {
            return this._httpClient.put<any>(`${this.projectEndpoint}(${projectId})/ProjectManager2/$ref`, data);
        }
        if (roleName.toLowerCase() === 'projectengineer') {
            return this._httpClient.put<any>(`${this.projectEndpoint}(${projectId})/ProjectEngineer/$ref`, data);
        }
        if (roleName.toLowerCase() === 'projectengineer2') {
            return this._httpClient.put<any>(`${this.projectEndpoint}(${projectId})/ProjectEngineer2/$ref`, data);
        }
        if (roleName.toLowerCase() === 'projectsuperintendent') {
            return this._httpClient.put<any>(`${this.projectEndpoint}(${projectId})/ProjectSuperintendent/$ref`, data);
        }
    }

    getUserProjectRoles(project: any, userId: string): string[] {
        const roles: string[] = [];
        if (project.ProjectManagerId === userId || project.ProjectManager2Id === userId) {
            roles.push('Project Manager');
        }
        if (project.ProjectEngineerId === userId || project.ProjectEngineer2Id === userId) {
            roles.push('Project Engineer');
        }
        if (project.ProjectSuperintendentId === userId) {
            roles.push('Project Superintendent');
        }
        return roles;
    }


    getProjectDetailsV1(id: number): Observable<any> {
        return this._httpClient.get<any>(`${this.projectEndpoint}(${id})?$select=Id,Number,Description,OriginalContract,StartDate,City,State,ProjectStatus&$expand=ProjectManager($select=Name,Email,Phone),ProjectEngineer($select=Name,Email,Phone),ProjectSuperintendent($select=Name,Email,Phone),ProjectForeman($select=Name,Email,Phone)`);
    }

    getProjectCrews(projectId: number, query: string): Observable<any[]> {
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})/Crews?${query}`);
    }

    getCostSummary(projectId: number, costCodeNo: string, costClassNo: string, startDate: string, endDate: string): Observable<any[]> {
        const sDate = format(new Date(startDate), 'yyyy-MM-dd');
        const eDate = format(new Date(endDate), 'yyyy-MM-dd');
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})/GetCostSummary(costCodeNo='${costCodeNo}',costClassNo='${costClassNo}',startDate=${sDate},endDate=${eDate})`);
    }

    getRevenue(projectId: number, startDate: string, endDate: string): Observable<any[]> {
        const sDate = format(new Date(startDate), 'yyyy-MM-dd');
        const eDate = format(new Date(endDate), 'yyyy-MM-dd');
        return this._httpClient.get<any[]>(`${this.projectEndpoint}(${projectId})/GetRevenue(startDate=${sDate},endDate=${eDate})`);
    }


    userHasProjectRole(role: string, userId: string, project): boolean {
        switch (role.toLowerCase()) {
          case "project manager":
            if (project?.ProjectManagerId === userId || project?.ProjectManager2Id === userId) {
              return true;
            } else {return false;}
          case "project engineer":
            if (project?.ProjectEngineerId === userId || project?.ProjectEngineer2Id === userId) {
              return true;} else { return false;}
          case "project superintendent":
            if (project?.ProjectSuperintendentId === userId) {
              return true;
            } else { return false;}
          default:
            return false;
        }
      }

    // ===========================================

    isRevenue(project: Project): boolean {
        return Number(project.originalContract) > 1000 ? true : false;
    }

    getProjects(): Observable<Project[]> {
        return this._httpClient.get<Project[]>(this.projectEndpoint);
    }

    getAllProjectsInTexas(): Observable<Project[]> {
        return this._httpClient.get<Project[]>(this.projectEndpoint + '/GetTexasProjects');
    }

    getProject(number: string): Observable<Project> {
        return this._httpClient.get<Project>(`${this.projectEndpoint}/${number}`);
    }

    isRevenueJob(job: Project): boolean {
        let isRev = false;
        if (parseFloat(job.originalContract) > 1000.00) {
            isRev = true;
        }
        return isRev;
    }
}
