<div class="header">
    <h2>Edit Credit Card</h2>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon color="warn">close</mat-icon>
    </button>
</div>
<br>
<div class="content">
    <form [formGroup]="formGroup">
        <div fxLayout="row">
            <mat-form-field appearance="fill" fxFlex.xs="90" class="full-width">
                <mat-label>CardHolder</mat-label>
                <input matInput aria-label="Card" [matAutocomplete]="cardHolderAuto" formControlName="CardHolder">
                <mat-autocomplete #cardHolderAuto="matAutocomplete" [displayWith]="getUserOptionText" [panelWidth]="350"
                    (optionSelected)='onUserChange()'>
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                        <span>{{user.Name}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>First Name</mat-label>
                <input matInput type="text" formControlName="CardHolderFirstName">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" formControlName="CardHolderLastName">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Account Number(Last 4)</mat-label>
                <input matInput type="text" formControlName="AccountNumber">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Credit Line(optional)</mat-label>
                <input matInput type="number" formControlName="CreditLine">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Issued On(optional)</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="IssuedOn">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Expires On(optional)</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="ExpiresOn">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
        <br>
        <div class="footer">
            <button mat-button color="primary" [mat-dialog-close]="null">CANCEL</button>
            <button mat-button color="warn" [mat-dialog-close]="formGroup" [disabled]="!formGroup.valid && !formGroup.dirty">SAVE</button>
        </div>
    </form>
</div>