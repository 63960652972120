<div fxLayout="column">
    <h2 mat-dialog-title>{{data.option}} Quantity</h2>
    <mat-dialog-content>
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Quantity Date</mat-label>
            <input matInput [min]="data.startDate" [max]="data.endDate" disabled [matDatepicker]="picker" [(ngModel)]="data.quantity.Date" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
        <br />
        <mat-form-field *ngIf="data.option == 'Update'" appearance="fill" class="full-width">
            <mat-label>Cost Code</mat-label>
            <input matInput aria-label="Cost Code" disabled [(ngModel)]="selectedCostCode">
        </mat-form-field>
        <mat-form-field *ngIf="data.option == 'Create'" appearance="fill" class="full-width">
            <mat-label>Cost Code</mat-label>
            <input matInput aria-label="Cost Code" [matAutocomplete]="autoCode" [formControl]="costCodesCtrl" (click)="$event.target.select()">
            <mat-icon matSuffix>playlist_add</mat-icon>
            <mat-autocomplete #autoCode="matAutocomplete" [displayWith]="getCostCodeOptionText" (optionSelected)='costCodeChanged($event.option.value)' [panelWidth]="350">
                <mat-option *ngFor="let costCode of filteredCostCodes | async" [value]="costCode">
                    <span>{{costCode.CostCodeNo}} - </span>
                    <small>{{costCode.Description}}</small>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <br />
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Amount</mat-label>
            <input matInput placeholder="" type="number" [(ngModel)]="data.quantity.Amount" required>
            <span matSuffix>{{data.quantity?.Unit}}</span>
        </mat-form-field>
        <p *ngIf="data.quantity.Amount === 0 || data.quantity.Amount === null" style="color: red;">Error! Quantity amount cannot be 0</p>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Notes</mat-label>
            <textarea matInput placeholder="" type="text" [(ngModel)]="data.quantity.Name" required></textarea>
        </mat-form-field>
    </mat-dialog-content>
    <br />
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between end">
        <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="data.quantity" style="float:right" [disabled]="data.quantity.Amount === 0 || data.quantity.Amount === null">Save</button>
    </mat-dialog-actions>
</div>