import { Menu } from './menu.model';
import { Permission } from '../../../security/shared/models/permission.model';

// public id: number,
// public title: string,
// public routerLink: string,
// public href: string,
// public icon: string,
// public target: string,
// public hasSubMenu: boolean,
// public parentId: number,
// public role: string
export const verticalMenuItems = [
    new Menu(1, 'Timecard', '/mytimecard', null, 'schedule', null, false, 0, [Permission.readTimecardsPermission]),
    new Menu(2, 'Projects', '/projects', null, 'construction', null, false, 0, [Permission.readProjectsPermission]),
    new Menu(2, 'Crew Dailys', '/dailys', null, 'groups', null, false, 0, [Permission.readCrewsPermission]),
    new Menu(2, 'Approvals', '/approvals', null, 'task_alt', null, false, 0, [Permission.approveTimecardsPermission, Permission.approveJhasPermission,
    Permission.approveQuantitiesPermission, Permission.approveDiariesPermission]),
    new Menu(7, 'HR', '', null, 'people', null, true, 0, [Permission.readEmployeesPermission]),
    new Menu(10, 'Timecards', '/timecards', null, 'access_time', null, false, 7, [Permission.readTimecardsPermission]),
    new Menu(10, 'Quantities', '/quantities', null, 'hourglass_empty', null, false, 7, [Permission.readQuantitiesPermission]),
    new Menu(11, 'Employees', '/employees', null, 'people_outline', null, false, 7, [Permission.readEmployeesPermission]),
    new Menu(19, 'Accounting', '', null, 'attach_money', null, true, 0, [Permission.readReceiptsPermission]),
    new Menu(21, 'Credit Card Receipts', '/receipts', null, 'receipt', null, false, 19, [Permission.readReceiptsPermission]),
    new Menu(31, 'Credit Cards Transactions', '/creditcardtransactions', null, 'payments', null, false, 19, [Permission.readReceiptsPermission]),
    new Menu(30, 'Credit Cards', '/creditcards', null, 'credit_card', null, false, 19, [Permission.exportReceiptsPermission]),
    new Menu(15, 'Reports', '', null, 'analytics', null, true, 0, [Permission.readExecReportsPermission]),
    // new Menu (16, 'Cost Projection', '/reports/cost-projection', null, '', null, false, 15, [Permission.readExecReportsPermission]),
    new Menu(17, 'Cost Summary Reports', '/reports/cost-summary', null, '', null, false, 15, [Permission.readExecReportsPermission]),
    // new Menu (18, 'AP WorkFlow', '/reports/ap-workflow', null, '', null, false, 15, [Permission.readExecReportsPermission]),
    new Menu(12, 'Admin', '', null, 'settings', null, true, 0, [Permission.updateUsersPermission, Permission.updateRolesPermission, Permission.updateCrewsPermission]),
    new Menu(13, 'Crews', '/crews', null, 'groups', null, false, 12, [Permission.readCrewsPermission]),
    new Menu(13, 'Users', '/users', null, 'manage_accounts', null, false, 12, [Permission.readUsersPermission]),
    new Menu(13, 'Roles', '/roles', null, 'security', null, false, 12, [Permission.readRolesPermission]),
    new Menu(14, 'Company', '/company', null, 'domain', null, false, 12, [Permission.readSettingsPermission]),
];

export const horizontalMenuItems = [
    new Menu(1, 'Timecard', '/mytimecard', null, 'schedule', null, false, 0, [Permission.readTimecardsPermission]),
    new Menu(2, 'Projects', '/projects', null, 'construction', null, false, 0, [Permission.readProjectsPermission]),
    new Menu(2, 'Crew Dailys', '/dailys', null, 'groups', null, false, 0, [Permission.readCrewsPermission]),
    new Menu(2, 'Approvals', '/approvals', null, 'task_alt', null, false, 0, [Permission.approveTimecardsPermission, Permission.approveJhasPermission,
    Permission.approveQuantitiesPermission, Permission.approveDiariesPermission]),
    new Menu(7, 'HR', '', null, 'people', null, true, 0, [Permission.readEmployeesPermission]),
    new Menu(10, 'Timecards', '/timecards', null, 'access_time', null, false, 7, [Permission.readTimecardsPermission]),
    new Menu(10, 'Quantities', '/quantities', null, 'hourglass_empty', null, false, 7, [Permission.readQuantitiesPermission]),
    new Menu(11, 'Employees', '/employees', null, 'people_outline', null, false, 7, [Permission.readEmployeesPermission]),
    new Menu(19, 'Accounting', '', null, 'attach_money', null, true, 0, [Permission.readReceiptsPermission]),
    new Menu(21, 'Credit Card Receipts', '/receipts', null, 'receipt', null, false, 19, [Permission.readReceiptsPermission]),
    new Menu(31, 'Credit Cards Transactions', '/creditcardtransactions', null, 'payments', null, false, 19, [Permission.readReceiptsPermission]),
    new Menu(30, 'Credit Cards', '/creditcards', null, 'credit_card', null, false, 19, [Permission.readReceiptsPermission]),
    new Menu(15, 'Reports', '', null, 'analytics', null, true, 0, [Permission.readExecReportsPermission]),
    // new Menu (16, 'Cost Projection', '/reports/cost-projection', null, '', null, false, 15, [Permission.readExecReportsPermission]),
    new Menu(17, 'Cost Summary Reports', '/reports/cost-summary', null, '', null, false, 15, [Permission.readExecReportsPermission]),
    // new Menu (18, 'AP WorkFlow', '/reports/ap-workflow', null, '', null, false, 15, [Permission.readExecReportsPermission]),
    new Menu(12, 'Admin', '', null, 'settings', null, true, 0, [Permission.updateUsersPermission, Permission.updateRolesPermission, Permission.updateCrewsPermission]),
    new Menu(13, 'Crews', '/crews', null, 'groups', null, false, 12, [Permission.readCrewsPermission]),
    new Menu(13, 'Users', '/users', null, 'manage_accounts', null, false, 12, [Permission.readUsersPermission]),
    new Menu(13, 'Roles', '/roles', null, 'security', null, false, 12, [Permission.readRolesPermission]),
    new Menu(14, 'Company', '/company', null, 'domain', null, false, 12, [Permission.readSettingsPermission]),
    new Menu(14, 'Company', '/company', null, 'domain', null, false, 12, [Permission.readSettingsPermission]),
];



