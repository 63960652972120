<div fxLayout="row" fxLayoutAlign="center center">
  <div>
    <button mat-icon-button (click)="updateDate(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>
  <div>
    <mat-form-field appearance="fill" style="width: 150px !important ;">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="dateCtrl" (dateChange)="onDateChangeEvent()">
      <mat-error *ngIf="dateCtrl.invalid">Please enter a valid date</mat-error>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <button mat-icon-button (click)="updateDate(1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>