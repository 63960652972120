import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from './pages/error/error.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MaterialModule } from './material/material.module';
import { GroupByPipe } from './pipes/group-by/group-by.pipe';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { WeekSelectorComponent } from './components/week-selector/week-selector.component';
import { QuantityDialogComponent } from './components/quantity-dialog/quantity-dialog.component';
import { EditTimecardDialogComponent } from './components/edit-timecard-dialog/edit-timecard-dialog.component';
import { CreateCreditCardDialogComponent } from './components/create-credit-card-dialog/create-credit-card-dialog.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { EditCreditCardDialogComponent } from './components/edit-credit-card-dialog/edit-credit-card-dialog.component';
import { CreditCardsListComponent } from './components/credit-cards-list/credit-cards-list.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule
  ],
  declarations: [
    ErrorComponent,
    NotFoundComponent,
    GroupByPipe,
    DateSelectorComponent,
    WeekSelectorComponent,
    QuantityDialogComponent,
    EditTimecardDialogComponent,
    CreateCreditCardDialogComponent,
    FileUploadDialogComponent,
    EditCreditCardDialogComponent,
    CreditCardsListComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    ErrorComponent,
    NotFoundComponent,
    GroupByPipe,
    DateSelectorComponent,
    WeekSelectorComponent,
    CreditCardsListComponent
  ]
})
export class SharedModule { }
